'use client'

import * as React from 'react'
import { useTheme } from 'next-themes'
import { LaptopIcon, MoonIcon, SunIcon } from 'lucide-react'
import { Label, RadioGroup } from '@viewpoint/ui'
import { useIsMounted } from '@/hooks'

export function LoginThemeToggle(): React.ReactElement | null {
  const { theme, setTheme } = useTheme()

  const isMounted = useIsMounted()

  if (!isMounted) {
    return null
  }

  return (
    <div className="flex items-center justify-start p-12 pb-4">
      <RadioGroup.Root
        className="focus-outline flex flex-row items-center justify-center gap-0.5 rounded-lg bg-gray-100 p-1 dark:bg-gray-950"
        defaultValue={theme}
        onValueChange={(value) => {
          setTheme(value)
        }}
        style={{
          outline: '',
        }}
      >
        <span className="flex">
          <RadioGroup.Item className="peer sr-only" id="light" value="light" />
          <Label
            className="inline-flex items-center justify-between rounded-md p-1 text-gray-400 peer-data-[state=checked]:bg-white peer-data-[state=checked]:text-gray-700 peer-data-[state=checked]:shadow dark:text-gray-500 dark:peer-data-[state=checked]:bg-gray-700 dark:peer-data-[state=checked]:text-gray-300 [&:has([data-state=checked])]:bg-white [&:has([data-state=checked])]:text-gray-700 [&:has([data-state=checked])]:shadow dark:[&:has([data-state=checked])]:bg-gray-700 dark:[&:has([data-state=checked])]:text-gray-300"
            htmlFor="light"
          >
            <SunIcon />
          </Label>
        </span>
        <span className="flex">
          <RadioGroup.Item className="peer sr-only" id="dark" value="dark" />
          <Label
            className="inline-flex items-center justify-between rounded-md p-1 text-gray-400 peer-data-[state=checked]:bg-white peer-data-[state=checked]:text-gray-700 peer-data-[state=checked]:shadow dark:text-gray-500 dark:peer-data-[state=checked]:bg-gray-700 dark:peer-data-[state=checked]:text-gray-300 [&:has([data-state=checked])]:bg-white [&:has([data-state=checked])]:text-gray-700 [&:has([data-state=checked])]:shadow dark:[&:has([data-state=checked])]:bg-gray-700 dark:[&:has([data-state=checked])]:text-gray-300"
            htmlFor="dark"
          >
            <MoonIcon />
          </Label>
        </span>
        <span className="flex">
          <RadioGroup.Item
            className="peer sr-only"
            id="system"
            value="system"
          />
          <Label
            className="inline-flex items-center justify-between rounded-md p-1 text-gray-400 peer-data-[state=checked]:bg-white peer-data-[state=checked]:text-gray-700 peer-data-[state=checked]:shadow dark:text-gray-500 dark:peer-data-[state=checked]:bg-gray-700 dark:peer-data-[state=checked]:text-gray-300 [&:has([data-state=checked])]:bg-white [&:has([data-state=checked])]:text-gray-700 [&:has([data-state=checked])]:shadow dark:[&:has([data-state=checked])]:bg-gray-700 dark:[&:has([data-state=checked])]:text-gray-300"
            htmlFor="system"
          >
            <LaptopIcon />
          </Label>
        </span>
      </RadioGroup.Root>
    </div>
  )
}
